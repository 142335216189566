import React, { useState, useEffect } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { Connection, PublicKey, Transaction, SystemProgram, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faCopy } from '@fortawesome/free-solid-svg-icons';

function formatAddress(address) {
    if (address && address.length > 8) {
        return `${address.slice(0, 8)}.....${address.slice(-4)}`;
    }
    return address;
}

function PaymentButton({ t }) {
    const wallet = useWallet();
    const location = useLocation(); 
    const [buttonText, setButtonText] = useState(t.payButton);
    const [rank, setRank] = useState(null);
    const [referralAddress, setReferralAddress] = useState(process.env.REACT_APP_DEFAULT_REFERRAL_ADDRESS || '');
    const [referralError, setReferralError] = useState('');
    const [userInfo, setUserInfo] = useState(null);
    const [referralLink, setReferralLink] = useState('');
    const [copySuccess, setCopySuccess] = useState(false);

    useEffect(() => {
        setButtonText(t.payButton);
    }, [t]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const referral = params.get('referral');
        if (referral) {
            setReferralAddress(referral);
        }
    }, [location]);

    useEffect(() => {
        if (wallet.connected) {
            fetch(`https://back.orbittrade.co/api/ranking/${wallet.publicKey.toString()}`)
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        setUserInfo(data.data);
                        setRank(data.data.rank);
                        setButtonText(t.congratulations);
                        const referralLink = `${window.location.origin}?referral=${wallet.publicKey.toString()}`;
                        setReferralLink(referralLink);
                    } else {
                        setUserInfo(null);
                        setRank(null);
                    }
                })
                .catch(error => {
                    console.error(t.fetchingUserInfoError, error);
                    setUserInfo(null);
                    setRank(null);
                });
        }
    }, [wallet.connected, t, wallet.publicKey]);

    const handlePayment = async () => {
        if (!wallet.connected) {
            alert(t.connectWallet);
            return;
        }

        if (!referralAddress) {
            setReferralError(t.referralRequiredError);
            return;
        }

        try {
            const connection = new Connection(process.env.REACT_APP_SOLANA_RPC_URL, 'confirmed');
            const recipient = new PublicKey(process.env.REACT_APP_RECEIVER_WALLET_PUBLIC_KEY);
            const amount = parseFloat(process.env.REACT_APP_SOL_TRANSFER_AMOUNT) * LAMPORTS_PER_SOL;

            const transaction = new Transaction().add(
                SystemProgram.transfer({
                    fromPubkey: wallet.publicKey,
                    toPubkey: recipient,
                    lamports: amount,
                })
            );

            //const { blockhash } = await connection.getRecentBlockhash();
            const { blockhash, lastValidBlockHeight } = await connection.getLatestBlockhash();
            transaction.recentBlockhash = blockhash;
            transaction.feePayer = wallet.publicKey;

            const signedTransaction = await wallet.signTransaction(transaction);
            const signature = await connection.sendRawTransaction(signedTransaction.serialize());

            await connection.confirmTransaction(signature, 'processed');

            fetch('https://back.orbittrade.co/api/payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    walletAddress: wallet.publicKey.toString(),
                    signature: signature,
                    referralAddress: referralAddress
                }),
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    alert(`${t.transactionSuccess} ${signature}`);
                    setUserInfo(data.userInfo);
                    setRank(data.userInfo.rank);
                    setButtonText(t.congratulations);

                    const referralLink = `${window.location.origin}?referral=${wallet.publicKey.toString()}`;
                    setReferralLink(referralLink);
                } else {
                    alert(`${t.paymentProcessingError} ${data.message}`);
                }
            })
            .catch(error => {
                console.error(t.processingPaymentError, error);
                alert(t.processingPaymentError);
            });

        } catch (error) {
            console.error(t.processingPaymentError, error);
            alert(t.processingPaymentError);
        }
    };

   const copyToClipboard = () => {
        const input = document.createElement('input');
        input.value = referralLink;
        document.body.appendChild(input);
        input.select();
        input.setSelectionRange(0, 99999); // For mobile devices
        document.execCommand('copy');
        document.body.removeChild(input);
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 5000);
    };

    return (
        <div className="flex flex-col items-center space-y-3 px-4 sm:px-16 md:px-8">
            {userInfo ? (
                <div>
                    <p>{t.congratulations}</p>
                     {/* <p>ReferralAddress: {formatAddress(userInfo.referralAddress)}</p>
                    <p>InvitedAddresses: {Array.isArray(userInfo.invitedAddresses) ? userInfo.invitedAddresses.map(formatAddress).join(', ') : ''}</p>
                */} </div>
            ) : (
                <>
                    <input
                        type="text"
                        value={referralAddress}
                        readOnly 
                        placeholder={t.referralPlaceholder}
                        className="my-input-class text-gray-400 p-2 w-full max-w-2xl rounded-lg border border-gray-300"
                        style={{ color: referralAddress ? 'black' : 'gray' }}
                    />
                    {referralError && <p className="text-red-500">{referralError}</p>}
                    <button 
                        onClick={handlePayment} 
                        className="bg-blue-600 text-white py-2 px-6 rounded-lg shadow-lg hover:bg-blue-800 transition duration-300 w-full lg:w-auto"
                    >
                        {buttonText}
                    </button>
                </>
            )}
            {rank && <p className="text-lg lg:text-xl">{t.connected} 100000</p>}

           {referralLink && (
                <div className="mt-4 p-4 bg-[#1E1E2F] rounded-lg shadow-lg text-center">
                    <p className="text-lg font-semibold text-[#14F195]">{t.inviteChain}</p>
                    <div className="flex justify-center items-center">
                        <input 
                            type="text"
                            value={referralLink}
                            readOnly
                            className="w-full mt-2 p-2 border border-[#9945FF] rounded-lg text-center text-gray-700"
                            onFocus={(e) => e.target.select()} // Manually select the text on focus
                        />
                        <button 
                            onClick={copyToClipboard} 
                            className="ml-2 bg-[#9945FF] text-white py-1 px-2 rounded-lg shadow-lg hover:bg-blue-600 transition duration-200"
                        >
                            <FontAwesomeIcon icon={faCopy} />
                        </button>
                    </div>
                    {copySuccess && <p className="mt-2 text-green-600">{t.copySuccess}</p>}
                    <p className="mt-2 text-sm text-gray-600">{t.shareLink}</p>
                </div>
            )}
        </div>
    );
}

export default PaymentButton;
