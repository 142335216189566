const locales = {
    en: {
        title: "Orbit Node Recruitment...",
        connectWallet: "Connect Wallet",
        payButton: "Purchase Node Pay 5SOL",
        Connected: "connected:",
        connected: "You have received ORBs tokens:",
        congratulations: "Congratulations! You are now a Orbit node",
        changeWallet: "Change Wallet",
        disconnect: "Disconnect",
        referralPlaceholder: "Referral Address (optional)",
        rank: "Rank",
        referralAddressError: "Address error",
        transactionSuccess: "Transaction successful! Signature: ",
        paymentProcessingError: "Payment processing failed: ",
        fetchingUserInfoError: "Error fetching user info:",
        processingPaymentError: "An error occurred while processing payment.",
        inviteChain:"Invite Chain",
        copyButton: "Copy",
        copySuccess: "Link copied to clipboard!",
        footerNote: "Providing multiple trading services for long-tail assets and trading users, offering the world's most professional new trading methods and scenarios for digital assets.",
    },
    zh: {
        title: "Orbit 节点招募中...",
        connectWallet: "连接钱包",
        payButton: "购买节点 支付5个sol",
        Connected: "已连接",
        connected: "您已获得代币ORBs:",
        congratulations: "恭喜！您已成为Orbit节点",
        changeWallet: "更换钱包",
        disconnect: "断开连接",
        referralPlaceholder: "推荐地址（可选）",
        rank: "排名",
        referralAddressError: "地址错误",
        transactionSuccess: "交易成功！签名：",
        paymentProcessingError: "支付处理失败：",
        fetchingUserInfoError: "获取用户信息出错：",
        processingPaymentError: "支付处理时发生错误。",
        inviteChain:"邀请链接",
        copyButton: "复制",
        copySuccess: "链接已复制到剪贴板！",
        footerNote: "Orbit 为长尾资产和交易用户提供多重交易服务，全球最专业的数字资产新型交易方式和场景",
    },
    ja: {
        title: "Orbit ノードの募集と...",
        connectWallet: "ウォレットを接続",
        payButton: "ノード購入、5 SOL 支払う",  
        connected: "ORBsトークンを獲得しました:",
        congratulations: "おめでとうございます！Orbitノードになりました",  
        changeWallet: "ウォレットを変更",
        disconnect: "切断",
        invalidReferralAddress: "無効な推薦アドレス",
        selfReferralError: "自分のウォレットを使用できません",
        referralPlaceholder: "推薦アドレス（任意）",
        rank: "ランキング",
        ReferralAddress: "推薦アドレス",
        InvitedAddresses: "招待されたアドレス",
        countdownTitle: "ノード募集とFoMo計画のカウントダウン...",
        inviteChain:"招待チェーンチェーン",
        copyButton: "コピー",
        copySuccess: "リンクがクリップボードにコピーされました！",
        footerNote: "ロングテール資産と取引ユーザーに多重取引サービスを提供し、世界で最も専門的なデジタル資産の新しい取引方法とシーンを提供します",
    },
    ko: {
        title: "Orbit 노드 모집 및...",
        connectWallet: "지갑 연결",
        payButton: "노드 구매, 5 SOL 결제",  
        connected: "ORBs 토큰을 받으셨습니다:",
        congratulations: "축하합니다! 이제 Orbit 노드가 되었습니다",  
        changeWallet: "지갑 변경",
        disconnect: "연결 해제",
        invalidReferralAddress: "잘못된 추천 주소",
        selfReferralError: "자신의 지갑을 사용할 수 없습니다",
        referralPlaceholder: "추천 주소(선택 사항)",
        rank: "순위",
        ReferralAddress: "추천 주소",
        InvitedAddresses: "초대된 주소",
        countdownTitle: "노드 모집 및 FoMo 계획 카운트다운...",
        inviteChain:"초대 체인 체인",
        copyButton: "복사",
        copySuccess: "링크가 클립보드에 복사되었습니다!",
        footerNote: "롱테일 자산과 거래 사용자를 위한 다중 거래 서비스를 제공하며, 세계에서 가장 전문적인 디지털 자산의 새로운 거래 방식과 시나리오를 제공합니다.",
    },
    vi: {
        title: "Orbit Tuyển dụng nút...",
        connectWallet: "Kết nối Ví",
        payButton: "Mua Nút, Thanh toán 5 SOL",  
        connected: "Bạn đã nhận được token ORBs:",
        congratulations: "Xin chúc mừng! Bạn đã trở thành nút Orbit",  
        changeWallet: "Thay đổi Ví",
        disconnect: "Ngắt kết nối",
        invalidReferralAddress: "Địa chỉ giới thiệu không hợp lệ",
        selfReferralError: "Không thể sử dụng ví của bạn",
        referralPlaceholder: "Địa chỉ giới thiệu (Tùy chọn)",
        rank: "Xếp hạng",
        ReferralAddress: "Địa chỉ giới thiệu",
        InvitedAddresses: "Địa chỉ được mời",
        countdownTitle: "Đếm ngược Tuyển dụng Nút và Kế hoạch FoMo...",
        inviteChain:"Chuỗi dây mời",
        copyButton: "Sao chép",
        copySuccess: "Liên kết đã được sao chép vào clipboard!",
        footerNote: "Cung cấp nhiều dịch vụ giao dịch cho tài sản đuôi dài và người dùng giao dịch, mang đến các phương thức và kịch bản giao dịch mới chuyên nghiệp nhất thế giới cho tài sản kỹ thuật số.",
    },
};

export default locales;
